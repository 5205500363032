import { ForwardedRef, forwardRef } from "react";
import formStyles from "../Checkout/checkout.module.css";
import type { InputProps } from "./Input";
import styles from "./input.module.css";

interface UIProps extends InputProps {
  conditionalStyle?: string;
}

const UI = forwardRef<HTMLInputElement, UIProps>(function UI(
  props,
  ref: ForwardedRef<HTMLInputElement>
) {
  const {
    type,
    placeholder,
    value,
    onChange,
    className,
    disabled = false,
    conditionalStyle = "",
    message = "",
    required = false,
    readonly = false,
    name,
    maxLength,
    autocomplete,
  } = props;
  return (
    <div className={styles.inputWrap}>
      <label
        className={`${className} ${formStyles.label} ${conditionalStyle}`}
        htmlFor={name}
      >
        {placeholder}
        {required && <span className={styles.required}> *</span>}
      </label>

      <input
        id={name}
        type={type}
        name={name}
        readOnly={readonly}
        ref={ref}
        autoComplete={autocomplete}
        value={value}
        onChange={onChange}
        className={`${styles.input} ${conditionalStyle}`}
        disabled={disabled}
        maxLength={maxLength}
      />

      {props?.children}
      <p className={`${conditionalStyle} ${styles.message}`}>{message} </p>
    </div>
  );
});

export default UI;
